import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас | Юридична фірма LegalEagle
			</title>
			<meta name={"description"} content={"Комплексні юридичні рішення для ваших потреб"} />
			<meta property={"og:title"} content={"Про Нас | Юридична фірма LegalEagle"} />
			<meta property={"og:description"} content={"Комплексні юридичні рішення для ваших потреб"} />
			<meta property={"og:image"} content={"https://map.quanticors.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://map.quanticors.com/img/3898103.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://map.quanticors.com/img/3898103.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://map.quanticors.com/img/3898103.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://map.quanticors.com/img/3898103.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://map.quanticors.com/img/3898103.png"} />
			
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Ваш шлях до юридичної досконалості
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Юридична фірма LegalEagle пропонує широкий спектр юридичних послуг, спрямованих на задоволення різноманітних потреб наших клієнтів. Наша команда досвідчених юристів надає першокласні юридичні консультації та представництво в різних галузях права. Незалежно від того, чи є ви фізичною особою, підприємством або організацією, ми маємо досвід та ресурси, які допоможуть вам впевнено орієнтуватися в правовому полі.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://map.quanticors.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://map.quanticors.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Наші сфери практики
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Корпоративне право
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Створення бізнесу: Ми допомагаємо у створенні корпорацій, партнерств і товариств з обмеженою відповідальністю, забезпечуючи дотримання всіх вимог законодавства.
Складання та перевірка договорів: Наші юристи розробляють, аналізують та ведуть переговори щодо укладення договорів з метою захисту ваших інтересів та мінімізації ризиків.
Злиття та поглинання: Ми надаємо юридичні консультації щодо складних процесів злиття, поглинання та корпоративної реструктуризації.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Сімейне право
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Розлучення та розставання: Співчутливе та ефективне юридичне представництво у справах про розлучення та розставання.
Опіка та підтримка дітей: Адвокація справедливої опіки над дітьми та механізмів підтримки, що відповідають найкращим інтересам дітей.
Послуги з усиновлення: Супровід вас у процесі усиновлення з ретельною юридичною підтримкою та турботою.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Право нерухомості
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Операції з нерухомістю: Допомога при купівлі, продажу та оренді нерухомості, забезпечення безперешкодного та юридично обґрунтованого проведення транзакцій.
Спори між орендодавцями та орендарями: Вирішення конфліктів між орендодавцями та орендарями за допомогою професійної медіації та судових послуг.
Девелопмент нерухомості: Юридичний супровід девелоперських проектів від планування до завершення.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="16px 16px 16px 16px"
				lg-order="-2"
				lg-width="100%"
				width="100%"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					overflow-x="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						position="absolute"
						display="block"
						width="100%"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://map.quanticors.com/img/5.jpg"
						object-fit="cover"
						top="auto"
						right={0}
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				align-items="flex-start"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://map.quanticors.com/img/6.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 8px 16px"
				>
					<Text
						lg-text-align="center"
						sm-font="--base"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Це лише короткий огляд того, що може запропонувати фірма LegalEagle. Щоб дізнатися більше про наші комплексні юридичні послуги та про те, як ми можемо вам допомогти, зв'яжіться з нами вже сьогодні. Наша команда готова надати кваліфіковану юридичну підтримку, якої ви потребуєте.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});